<template>
  <a-modal
    v-model:visible="showAddModelArchivedCycleModal"
    closable
    :title="`${modelTypeToBeEdited ? 'Update' : 'Add'} Model Type`"
    destroy-on-close
    :ok-text="modelTypeToBeEdited ? 'Update' : 'Add'"
    width="30vw"
    :body-style="{
      height: '50vh',
      display: 'flex',
      flexDirection: 'column',
    }"
    :ok-button-props="{ disabled: isDisabled, loading: isCreatingArchivedCycle }"
    @ok="(handleSubmit())"
    @cancel="setShowAddModelArchivedCycleModal(false)"
  >
  <a-form
    layout="vertical"
    :model="roleName"
    ref="formRef"
    name="add_role"
    @finish="addRole()"
  >
    <div class="row">
      <div class="col-sm-12">
        <a-form-item label="Organization" name="roleName">
          <!-- <a-input placeholder="ex: admin" v-model:value="roleName"> </a-input> -->
            <a-select
              show-search
              id="select-org-filter"
              v-model:value="selectedOrganization"
              style="width: 100%"
              placeholder="Select Organization"
              :options="organizationOptions"
              :filter-option="(input, option) => option.label.toLowerCase().includes(input.toLowerCase())"
            />
        </a-form-item>
        <a-form-item label="Device" name="select-device-filter">
          <!-- <a-input placeholder="ex: admin" v-model:value="roleName"> </a-input> -->
            <a-select
              show-search
              :disabled="deviceOptions.length == 0"
              id="select-device-filter"
              v-model:value="selectedDevice"
              style="width: 100%"
              placeholder="Select Device"
              :options="deviceOptions"
              @change="handleDeviceChange"
              :filter-option="(input, option) => option.label.toLowerCase().includes(input.toLowerCase())"
            />
        </a-form-item>
        <a-form-item label="Operation" name="select-device-filter">
          <!-- <a-input placeholder="ex: admin" v-model:value="roleName"> </a-input> -->
            <a-select
              show-search
              :disabled="taskOptions.length == 0"
              id="select-device-filter"
              v-model:value="selectedTask"
              style="width: 100%"
              placeholder="Select Device"
              :options="taskOptions"
              :filter-option="(input, option) => option.label.toLowerCase().includes(input.toLowerCase())"
            />
        </a-form-item>
        <a-form-item label="Date" name="select-device-filter">
            <a-range-picker
              style="width: 100%"
              v-model:value="dateTime"
              :show-time="{ format: 'HH:mm' }"
              :ranges="dateRanges"
              format="YYYY/MM/DD HH:mm"
            />
        </a-form-item>
      </div>
    </div>
    <!-- <div class="buttons-w d-flex justify-content-end">
      <a-button type="primary" html-type="submit" :disabled="isRoleExist">
        Create
      </a-button>
    </div> -->
  </a-form>
</a-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import DataService from '../../../../services/device'
import TaskService from 'src/services/tasks.js';
import dateHelper from 'src/components/shared/Helpers/dateHelper';


export default {
  name: 'AddRole',
  emits: ['toggleModal'],
  setup: () => ({
    toast: useToast()
  }),
  data() {
    return {
      roleName: '',
      organization: [],
      selectedOrganization: null,
      devices: [],
      selectedDevice: null,
      tasks: [],
      selectedTask: null,
      dateTime: null,
      dateRanges: null,

      isCreatingArchivedCycle: false,
    };
  },
  watch: {
    async selectedOrganization(value){
      await this.fetchDevices()
      await this.fetchTasks()
    },
  },
  computed: {
    isRoleExist() {
      return this.roleName === '';
    },
    ...mapGetters(['showAddModelArchivedCycleModal']),

    organizationOptions() {
      return this.organization?.map((device) => ({
        value: device.Org_name,
        label: device.Org_name,
      }));
    },

    deviceOptions() {
      return this.devices?.map((device) => ({
        value: device.Device,
        label: device.display_name || device.Serial_number
      }));
    },

    taskOptions() {
      return this.tasks?.map((task) => ({
        value: task.id,
        label: task.taskName
      }));
    },
  },
  async created(){
    let [err, data] = await DataService.getAllOrganizations()
    this.organization = data
  },
  methods: {
    ...mapActions(['setShowAddModelArchivedCycleModal', 'createArchivedCycles', 'fetchArchivedCycles']),
    async addRole() {
      const res  = await this.addNewRole(this.roleName);
      if(!res) this.toast.error("Couldn't add new role!")
      else this.toast.success('Role added successfully!')
      this.$emit('toggleModal', false);
    },

    async handleSubmit(){
      if(!this.selectedOrganization || !this.selectedDevice || !this.selectedTask || !this.dateTime[0].$d || !this.dateTime[1].$d){
        return this.toast.error("All fields are required")
      }
      this.isCreatingArchivedCycle = true
      let startDate = dateHelper.formatDate(this.dateTime[0].$d, 'YYYY-MM-DD HH:mm:ss')
      let endDate = dateHelper.formatDate(this.dateTime[1].$d, 'YYYY-MM-DD HH:mm:ss')
      let payload = {
        organization: this.selectedOrganization,
        device_id: this.selectedDevice,
        task_id: this.selectedTask,
        start_datetime: startDate,
        end_datetime: endDate
      }
      try {
        await this.createArchivedCycles(payload)
      } catch (error) {
        this.isCreatingArchivedCycle = false
        return
      }
      await this.fetchArchivedCycles()
      this.resetForm()
      this.isCreatingArchivedCycle = false
      this.setShowAddModelArchivedCycleModal(false)
    },

    resetForm(){
      this.selectedOrganization = null
      this.selectedDevice = null
      this.selectedTask = null
      this.dateTime = null
      this.dateRanges = null
      this.tasks = []
      this.devices = []
    },

    async fetchDevices(){
      let [err, devices] = await DataService.fetchAllDevicesOfOrg(this.selectedOrganization)
      if(err){
        console.log(err)
        return
      }
      this.devices = devices
    },

    async fetchTasks(){
      let query = {organizations : this.selectedOrganization}
      let [err, tasks] = await TaskService.fetchAllTaskAdminPanel(query, false)
      if(err){
        console.log(err)
        return
      }
      this.tasks = tasks
    },
    handleDeviceChange(value){
      this.selectedDevice = value
    }
  }
};
</script>
