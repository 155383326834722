<template>
  <div class="p-4">
    <div class="mb-1 d-flex justify-content-between align-items-center">
      <a-input style="width: 400px" placeholder="Search by Organization, Device or Task" :value="search" @input="handleChange" />
      <a-button
        class="mb-3"
        type="primary"
        @click="setShowAddModelArchivedCycleModal(true)"
      >
        <template #icon>
          <i class="bi bi-plus mr-1 " />
        </template>
        Archived Cycles
      </a-button>
    </div>
    <archived-cycles />
    <add-archived-cycle />
  </div>
</template>

<script>
import ArchivedCycles from './ArchivedCycleList';
import AddArchivedCycle from './AddArchivedCycle.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    ArchivedCycles,
    AddArchivedCycle,
  },
  data() {
    return {
      search: '',
    };
  },
  methods: {
    ...mapActions(['setShowAddModelArchivedCycleModal', 'filterArchivedCycles']),

    async handleChange(event) {
      const value = event.target.value;
      this.search = value;
      this.filterArchivedCycles(value.toLowerCase()); // Dispatch the filter action with the search keyword
    },
  },
};
</script>
