export const columns = [
    {
      title: 'Organization',
      dataIndex: 'organization',
      key: 'organization',
      align: 'center',
    },
    {
      title: 'Operation',
      dataIndex: 'task',
      key: 'task',
      align: 'center',
    },
    {
      title: 'Device',
      dataIndex: 'device',
      key: 'device',
      align: 'center',
    },
    {
      title: 'Start Date',
      dataIndex: 'start_datetime',
      key: 'start_datetime',
      align: 'center',
    },
    {
      title: 'End Date',
      dataIndex: 'end_datetime',
      key: 'end_datetime',
      align: 'center',
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
    }
  ];